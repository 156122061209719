<template>
  <AuthLogin>
    <template
      #first-title
    >
      PASSWORD CREATED
    </template>
    <template
      #second-title
    >
      Go ahead - log in
    </template>
  </AuthLogin>
</template>

<script>
import AuthLogin from '@/modules/auth/views/Login.vue'

export default {
  name: 'FirstLogin',
  components: {
    AuthLogin,
  },
}
</script>

<style lang="scss" scoped>

</style>
