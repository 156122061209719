<template>
  <b-container
    class="vh-80 d-flex align-items-center justify-content-center text-center"
    fluid
  >
    <b-row>
      <b-col class="large-col pl-5 pr-5">
        <router-link to="/login">
          <LogoWithTextDark class="mb-4 mr-4" />
        </router-link>
        <b-alert
          :show="!!error"
          variant="danger"
        >
          {{ error }}
        </b-alert>
        <b-col class="first-text text-left pl-0 mt-3">
          <slot name="first-title">
            LOGIN
          </slot>
        </b-col>
        <b-col class="second-text text-left pl-0 mt-1">
          <slot name="second-title" />
        </b-col>
        <b-col class="third-text text-left pl-0 mt-3">
          Email
        </b-col>
        <b-form @submit.prevent="submit">
          <b-form-input
            id="relancer-email"
            v-model="form.email"
            type="email"
            required
            placeholder="Enter email"
            autocomplete="email"
            class="mb-3"
          />
          <b-col class="third-text text-left pl-0 mt-3">
            Password
          </b-col>
          <b-form-input
            id="relancer-password"
            v-model="form.password"
            required
            placeholder="Enter password"
            type="password"
            autocomplete="current-password"
            class="mb-3"
          />

          <b-button
            class="w-100 mt-3"
            type="submit"
            variant="primary"
          >
            <b-spinner
              v-if="loading"
              small
              label="Loading..."
            />
            <span v-else>
              Login
            </span>
          </b-button>
          <b-row>
            <b-col>
              <b-form-checkbox
                id="checkbox-1"
                v-model="form.rememberMe"
                class="terms-box mt-4 mb-4 pl-0"
                name="checkbox-1"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Remember me
              </b-form-checkbox>
            </b-col>
            <b-col class="forgot-pw pr-0">
              <a
                v-b-modal.modal-multi-1
                variant="primary"
              >
                Change password
              </a>
              <b-modal
                id="modal-multi-1"
                size="md"
                ok-only
                ok-title="Send"
                no-stacking
                centered
                :ok-disabled="!form.email"
                @ok="submitForgotPw"
              >
                <div class="textarea-feedback">
                  <b-form @submit.prevent="submitForgotPw">
                    <h5>Enter your email here</h5>
                    <label>In order to help reset your password we need your email for it.</label>
                    <b-form-input
                      v-model="form.email"
                      required
                      placeholder="Enter your email here"
                      rows="3"
                      max-rows="9"
                      autofocus
                    />
                  </b-form>
                </div>
              </b-modal>
            </b-col>
          </b-row>
        </b-form>
        <div class="closing-txt mt-5">
          New to Relancer? <a href="https://relancer.com/signup">Sign up now</a>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LogoWithTextDark from '@/components/svgs/LogoWithTextDark.vue'
import { readError } from '@/helpers/axios'

export default {
  name: 'AuthLogin',

  components: {
    LogoWithTextDark,
  },

  data () {
    return {
      form: {
        email: '',
        password: '',
        rememberMe: 'accepted',
      },
      error: '',
      loading: false,
    }
  },

  methods: {
    submit () {
      this.loading = true
      this.$store.dispatch('auth/login', this.form)
        .catch(err => {
          this.error = readError(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    submitForgotPw () {
      if (this.form.email) {
        this.$store.dispatch('auth/forgotPassword', this.form.email)
        this.showConfirmationMsg()
      }
    },
    showConfirmationMsg () {
      this.$bvModal.msgBoxOk('You should receive email from Relancer usually in 5 minutes in order to reset your password.', {
        title: 'Email Sent!',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.vh-80 {
  height: 100vh;
  background:#E5E5E5;
}
.large-col {
  width: 400px;
  height: 500px;
  background: #FFF;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.07);
}
.first-text {
  color: #EE8B7B;
  font-size: 14px;
  font-weight: bold;
}
.second-text {
  color: #19242C;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
.third-text {
  color: #465C6C;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
}
.terms-box {
  margin: auto;
  color: #455360;
  font-size: 12px;
  line-height: 26px;
}
.forgot-pw {
  color: #EE8B7B;
  margin: auto;
  font-size: 12px;
  line-height: 26px;
}
.closing-txt {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
}
.textarea-feedback {
  width: 80%;
  margin: auto;
}
</style>
